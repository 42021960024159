import instagram from '../assets/instagram.png'
//import appstore from '../assets/IMG_20201124_112759.png'
//import dribbble from '../assets/dribbble.png'
//import github from '../assets/github.png'
import website from '../assets/website.png'
import fuckrap from '../assets/fuckrap.png'
import spotify from '../assets/spotify.png'
import cloud from '../assets/cloud.png'
import sslimgs from '../assets/sslimgs.png'
const items = [{
    "title": "博客",
    "subtitle": "winsunz.com | Shit bro",
    "image": website,
    "link": "https://winsunz.com"
},
{
    "title": "Instagram",
    "subtitle": "@winsunz | 八百年不更新一次",
    "image": instagram,
    "link": "https://instagram.com/winsunz"
},
{
    "title": "Fuck Rap",
    "subtitle": "妈的豆瓣以前的歌笑死",
    "image": fuckrap,
    "link": "https://site.douban.com/winsunz/room/3581322/"
},
{
    "title": "Spotify",
    "subtitle": "Spotify是一个好音乐软件-Obama",
    "image": spotify,
    "link": "https://open.spotify.com/user/winsunz"
},
{
    "title": "SSLIMGS",
    "subtitle": "用chevereto搭建的一个图床",
    "image": sslimgs,
    "link": "https://sslimgs.com"
},
{
    "title": "1kb.space",
    "subtitle": "用filerun搭建的一个网盘",
    "image": cloud,
    "link": "https://1kb.space"
}
]
//{
//    "title": "GitHub",
//    "subtitle": "@devgossips | 🏠 of my open-source projects",
//    "image": github,
//    "link": "https://github.com/devgossips"
//},
//{
//    "title": "Apps",
//    "subtitle": "Hub of my awesome🔥 Apps",
//    "image": appstore,
//    "link": "#"
//},
//{
//    "title": "Dribbble",
//    "subtitle": "@virtuonic | Hub to the Shots of my 😎 apps ",
//    "image": dribbble,
//    "link": "https://dribbble.com/virtuonic"
//},


export default items
